/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../services";
import { SILVERBACK, STATUS_CODES } from "../../services/apiConstant";
import { errorToast, successToast } from "components/showToast";

const slice = createSlice({
  name: "assets",
  initialState: {
    realEstateDetail: null,
    addRealEstateData: null,
    otherTypeList: null,
    insuranceData: null,
    insurancePeriodData: [],
    insuranceYearsData: [],
    taxesListData: [],
    inflationTypesData: [],
    eliminationPeriodsData: [],
    benefitsTypesData: [],
    benefitPeriodData: [],
    cashValueApproachesData: [],
    distributionsData: [],
    deathBenefitOptionsData: [],
    insuranceDetails: null,
    getTokenIncome: null,
    getToken: null,
    dashboardData: null,
    advertiseListData: [],
    totalAdvertiseCount: null,
    bannerCategoryList: [],
    userChartData: [],
  },
  reducers: {
    realEstateInfoSuccess: (state, action) => {
      state.realEstateDetail = action.payload.data;
    },
    clearRealEstaetDetailSuccess: (state, action) => {
      state.realEstateDetail = action.payload;
      state.addRealEstateData = action.payload;
    },
    addRealEstateSuccess: (state, action) => {
      state.addRealEstateData = action.payload.data;
    },
    getOtherTypeListSuccess: (state, action) => {
      state.otherTypeList = action.payload.data.assetDebtData;
    },
    getInsuranceTypesSuccess: (state, action) => {
      state.insuranceData = action.payload;
    },
    getInsurancePeriodSuccess: (state, action) => {
      state.insurancePeriodData = action.payload;
    },

    getInsuranceYearSuccess: (state, action) => {
      state.insuranceYearsData = action.payload;
    },
    getTaxesListSuccess: (state, action) => {
      state.taxesListData = action.payload;
    },
    getInflationTypesSuccess: (state, action) => {
      state.inflationTypesData = action.payload;
    },
    getEliminationPeriodSuccess: (state, action) => {
      state.eliminationPeriodsData = action.payload;
    },
    getBenefitsTypesSuccess: (state, action) => {
      state.benefitsTypesData = action.payload;
    },
    getBenefitPeriodsSuccess: (state, action) => {
      state.benefitPeriodData = action.payload;
    },
    getCashValueApproachesSuccess: (state, action) => {
      state.cashValueApproachesData = action.payload;
    },
    getDistributionsListSuccess: (state, action) => {
      state.distributionsData = action.payload;
    },
    getDeathBenefitsListSuccess: (state, action) => {
      state.deathBenefitOptionsData = action.payload;
    },
    getInsuranceDetailsSuccess: (state, action) => {
      state.insuranceDetails = action.payload.data.db_insurance;
    },
    clearInsuranceDetailsSuccess: (state, action) => {
      state.insuranceDetails = null;
    },
    getinitSuccess: (state, action) => {
      state.getToken = action.payload.data;
    },
    getinitIncomeSuccess: (state, action) => {
      state.getTokenIncome = action.payload.data;
    },
    getDashboardDataSuccess: (state, action) => {
      state.dashboardData = action.payload.data;
    },
    loadingSuccess: (state, action) => {
      state.isLoading = action.payload;
    },
    getAdvertiseListingSucces: (state, action) => {
      if (action.payload.page === 1) {
        state.advertiseListData = [];
      }
      state.advertiseListData = [...action.payload.data.banners];
      state.totalAdvertiseCount = action.payload.data.total_count;
      state.isLoading = false;
    },
    getBannerCategoryListSucces: (state, action) => {
      state.bannerCategoryList = action.payload.data;
    },
    getAdvertiseDetailSuccess: (state, action) => {
      state.advertiseDetail = action.payload.data;
    },
    deleteAdvertiseSuccess: (state, action) => {
      state.advertiseListData = state.advertiseListData.filter(
        (item) => item.id != action.payload
      );
      state.isLoading = false;
    },
    clearAdvertiseDetailsSuccess: (state, action) => {
      state.advertiseDetail = action.payload;
    },
    getUserChartDataSuccess: (state, action) => {
      state.userChartData = action.payload.data.result;
    },
    clearUserChartDataSuccess: (state, action) => {
      state.userChartData = action.payload;
    },
  },
});

// ** Actions **
const {
  realEstateInfoSuccess,
  clearRealEstaetDetailSuccess,
  addRealEstateSuccess,
  getOtherTypeListSuccess,
  getInsuranceTypesSuccess,
  getInsurancePeriodSuccess,
  getInsuranceYearSuccess,
  getTaxesListSuccess,
  getInflationTypesSuccess,
  getEliminationPeriodSuccess,
  getBenefitsTypesSuccess,
  getBenefitPeriodsSuccess,
  getCashValueApproachesSuccess,
  getDistributionsListSuccess,
  getDeathBenefitsListSuccess,
  getInsuranceDetailsSuccess,
  clearInsuranceDetailsSuccess,
  getinitIncomeSuccess,
  getinitSuccess,
  getDashboardDataSuccess,
  loadingSuccess,
  getAdvertiseListingSucces,
  getBannerCategoryListSucces,
  getAdvertiseDetailSuccess,
  deleteAdvertiseSuccess,
  clearAdvertiseDetailsSuccess,
  getUserChartDataSuccess,
  clearUserChartDataSuccess
} = slice.actions;
// get real estate info
export const getRealEstateInfo = (data, setIsLoading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK.getRealEstateInfo, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(realEstateInfoSuccess(response.data));
      } else {
        errorToast(response.data.detail, "states");
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// clear  real estate info
export const clearRealEstateDetail = () => async (dispatch) => {
  dispatch(clearRealEstaetDetailSuccess(null));
};

// add  real estate

export const addRealEstate = (data, setIsLoading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK.addRealEstate, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response.data.message);
        dispatch(addRealEstateSuccess(response.data));
      } else {
        errorToast(response.data.detail, "states");
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get other types list

export const getOtherTypeList = (data) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK.otherTypeList}/${data?.type}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getOtherTypeListSuccess(response.data));
        } else {
          errorToast(response.data.detail, "states");
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const addOtherTypeAssets =
  (data, id, navigate, setIsLoading, setFormData) => async () => {
    try {
      await api.post(SILVERBACK.addOtherTypeAssets, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          successToast(response.data.message);
          navigate(`/assetsDetail/${id}`);
          setFormData({});
        } else {
          errorToast(response.data.detail, "states");
        }
        if (setIsLoading) {
          setIsLoading(false);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

//get Insurance Types
export const getInsuranceTypes = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getInsuranceTypes).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getInsuranceTypesSuccess(response?.data?.data?.types));
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get insurance period list
export const getInsurancePeriod = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getInsurancePeriod).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getInsurancePeriodSuccess(response?.data?.data?.periods));
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// create insurance

export const createInsurance =
  (request, id, navigate, setIsloading) => async () => {
    try {
      await api.post(SILVERBACK.createInsurance, request).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          successToast(response.data.message, "support");
          navigate(`/assetsDetail/insurance/${id}`);
        } else {
          errorToast(response.data?.detail, "support");
        }
        setIsloading(false);
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

//   get Insurance Year

export const getInsuranceYear = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getInsuranceYear).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getInsuranceYearSuccess(response?.data?.data?.years));
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Taxes List

export const getTaxesList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getTaxesList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getTaxesListSuccess(response?.data?.data?.taxes));
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Inflation Types

export const getInflationTypes = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getInflationTypes).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(
          getInflationTypesSuccess(response?.data?.data?.inflation_types)
        );
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Elimination Period

export const getEliminationPeriod = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getEliminationPeriod).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(
          getEliminationPeriodSuccess(response?.data?.data?.elimination_periods)
        );
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Benefits Types

export const getBenefitsTypes = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getBenefitsTypes).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getBenefitsTypesSuccess(response?.data?.data?.benefit_types));
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Benefit Periods

export const getBenefitPeriods = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getBenefitPeriods).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(
          getBenefitPeriodsSuccess(response?.data?.data?.benefitperiods)
        );
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// get Cash Value Approaches

export const getCashValueApproaches = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getCashValueApproaches).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(
          getCashValueApproachesSuccess(
            response?.data?.data?.cashvalueapproaches
          )
        );
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Distributions List

export const getDistributionsList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getDistributionsList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(
          getDistributionsListSuccess(response?.data?.data?.distributions)
        );
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Death Benefits List

export const getDeathBenefitsList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getDeathBenefitsList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(
          getDeathBenefitsListSuccess(response?.data?.data?.deathBenefits)
        );
      } else {
        errorToast("error", response.data?.detail, "support");
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Insurance Details

export const getInsuranceDetails = (id, setIsloading) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK.getInsuranceDetails}/${id}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getInsuranceDetailsSuccess(response?.data));
        } else {
          errorToast(response.data?.detail, "support");
        }
        setIsloading(false);
      });
  } catch (e) {
    return console.error(e.message);
  }
};

// clear insurance details

export const clearInsuranceDetails = () => async (dispatch) => {
  dispatch(clearInsuranceDetailsSuccess(null));
};

// update Insurance
export const updateInsurance =
  (request, insuranceId, id, navigate, setIsloading) => async () => {
    try {
      await api
        .put(`${SILVERBACK.updateInsurance}/${insuranceId}`, request)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            successToast(response.data.message, "support");
            navigate(`/assetsDetail/insurance/${id}`);
          } else {
            errorToast("error", response.data?.detail, "support");
          }
          setIsloading(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getinit = (id) => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  try {
    await api
      .post(`${SILVERBACK.getInitURL}?user_id=${id}&platform=WEBSITE`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getinitSuccess(response.data));
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};

// export const getinitIncome = (id) => async (dispatch) => {
//   // dispatch(loadingSuccess(true));
//   try {
//     await api
//       .get(`${SILVERBACK.getInitIncomeURL}?platform=WEBSITE&userId=${id}`)
//       .then((response) => {
//         if (response.status === STATUS_CODES.SUCCESS) {
//           dispatch(getinitIncomeSuccess(response.data));
//         }
//       });
//   } catch (e) {
//     return console.error(e.message);
//   }
// };

//  plaid exchange for income
export const plaidExchangeIncome =
  (id, data, setIsloading, fetchData, closeDialog) => async () => {
    try {
      await api
        .post(`${SILVERBACK.plaidExchangeIncomeURL}?userId=${id}`, data)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            successToast(response.data.message);
            closeDialog();
            fetchData();
          } else {
            errorToast(response.data.detail, "states");
          }
          setIsloading(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

//  plaid exchange

// add quiltt connection 
export const plaidExchange =
  (id,userId, setIsloading, fetchData, closeDialog) => async () => {
    try {
      await api
        .post(`${SILVERBACK.addQuilttConnection}?user_id=${userId}&connectionId=${id}`)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            successToast(response.data.message, "success");
            if (closeDialog) {
              closeDialog();
            }
            if (fetchData) {
              fetchData();
            }
          } else {
            errorToast(response.data.detail, "states");
          }
          setIsloading(false);
        });
    } catch (e) {
      setIsloading(false);
      return console.error(e.message);
    }
  };
// add Manual Assets Liabilities
export const addManualAssetsLiabilities =
  (data, id, navigate, setIsLoading, setFormData) => async () => {
    try {
      await api
        .post(SILVERBACK.addManualAssetsLiabilities, data)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            successToast(response.data.message);
            navigate(`/assetsDetail/${id}`);
            setFormData({});
          } else {
            errorToast(response.data.detail, "states");
          }
          if (setIsLoading) {
            setIsLoading(false);
          }
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

// add Manual Income Assets Liabilities
export const addManualIncomeAssetsLiabilities =
  (data, id, navigate, setIsLoading, setFormData) => async () => {
    try {
      await api
        .post(SILVERBACK.addManualIncomeAssetsLiabilities, data)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            successToast(response.data.message);
            navigate(`/assetsDetail/${id}`);
            setFormData({});
          } else {
            errorToast(response.data.detail, "states");
          }
          if (setIsLoading) {
            setIsLoading(false);
          }
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

// get Dashboard Data
export const getDashboardData = (request) => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  try {
    await api.post(SILVERBACK.getDashboardData, request).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getDashboardDataSuccess(response.data));
      } else {
        errorToast(response?.data?.detail);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get UserChart Data
export const getUserChartData =
  (request, setToatlUserMonths, setUsers) => async (dispatch) => {
    setToatlUserMonths([]);
    setUsers([]);
    // dispatch(loadingSuccess(true));
    try {
      await api.post(SILVERBACK.getUserChartData, request).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getUserChartDataSuccess(response.data));
        } else {
          errorToast(response?.data?.detail);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
// clear UserChart Data
export const clearUserChartData = () => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  dispatch(clearUserChartDataSuccess(null));
};
//  get Advertise List

export const getAdvertiseList = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK.getAdvertiseList, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(
          getAdvertiseListingSucces({
            ...response.data,
            page: data.page,
          })
        );
      } else {
        errorToast.error(response?.data?.detail);
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

//  get Banner Category List

export const getBannerCategoryList = (data, status) => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getBannerCategoryList, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getBannerCategoryListSucces(response.data));
      } else {
        errorToast.error(response?.data?.detail);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

// add Advertise
export const addAdvertise = (data, navigate, setIsLoading) => async () => {
  try {
    await api.post(SILVERBACK.addAdvertise, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response.data.message);
        navigate(`/advertise-management`);
      } else {
        errorToast(response.data.detail);
      }
      setIsLoading(true);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Advertise Detail
export const getAdvertiseDetail = (data, setIsLoading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK.getAdvertiseDetail, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getAdvertiseDetailSuccess(response?.data));
      } else {
        errorToast(response.data.detail);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// update Advertise
export const updateAdvertise = (data, navigate, setIsLoading) => async () => {
  try {
    await api.post(SILVERBACK.updateAdvertise, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response.data.message);
        navigate(`/advertise-management`);
      } else {
        errorToast(response.data.detail);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// delete Advertise
export const deleteAdvertise = (id, setOpenAlertDialog) => async (dispatch) => {
  dispatch(loadingSuccess(true));
  try {
    await api.delete(`${SILVERBACK.deleteAdvertise}/${id}`).then((response) => {
      let result = response.data;
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(deleteAdvertiseSuccess(id));
        successToast(result.message);
        setOpenAlertDialog(false);
      } else {
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

// clear advertise details
export const clearAdvertiseDetails = () => async (dispatch) => {
  dispatch(clearAdvertiseDetailsSuccess(null));
};

export default slice.reducer;
