// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { getAnalytics } from 'firebase/analytics';
// import { REACT_KEY } from 'utils/appConstant';
// import Cookies from 'js-cookie';

// const firebaseConfig = {
//   apiKey: 'AIzaSyDCnCSseW3EfEOv2zCYqBENCK5HBxff0rc',
//   authDomain: 'silverback-mobile-2024.firebaseapp.com',
//   projectId: 'silverback-mobile-2024',
//   storageBucket: 'silverback-mobile-2024.appspot.com',
//   messagingSenderId: '909618343007',
//   appId: '1:909618343007:web:2a5223ea43134be562bfea',
//   measurementId: 'G-XYEY9T9KZG'
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// // Analytics service
// export const analytics = getAnalytics(app);
// // Messaging service
// export let messaging = {};
// if ('serviceWorker' in navigator) {
//   messaging = getMessaging(app);
// }

// export const requestForToken = async () => {
//   if ('serviceWorker' in navigator) {
//     // Initialize Messaging if needed
//     // const messaging = getMessaging(app);
//     // Your messaging setup code here

//     // Register the service worker
//     navigator.serviceWorker
//       .register('/firebase-messaging-sw.js')
//       .then((registration) => {
//         console.log('Service Worker registered with scope:', registration.scope);
//       })
//       .catch((error) => {
//         console.error('Service Worker registration failed:', error);
//       });
//   } else {
//     console.warn('Service workers are not supported in this browser.');
//   }

//   try {
//     //requesting permission using Notification API

//     const permission = await Notification.requestPermission();
//     if (permission === 'granted') {
//       const token = await getToken(messaging, {
//         vapidKey: REACT_KEY.REACT_APP_FIREBASE_VAPID_KEY
//       });

//       //We can send token to server
//       console.log('Token generated : ', token);
//       Cookies.set(REACT_KEY.FCM_TOKEN_KEY, token);
//     } else if (permission === 'denied') {
//       //notifications are blocked
//       console.log('Please allow notification persmission to have updates to your app.');
//     } else {
//       console.log('Please allow notification persmission to have updates to your app.');
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log('payload', messaging);
//       resolve(payload);
//     });
//   });
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import Cookies from "js-cookie";
import { REACT_KEY } from "utils/appConstant";

const firebaseConfig = {
  apiKey: "AIzaSyDCnCSseW3EfEOv2zCYqBENCK5HBxff0rc",
  authDomain: "silverback-mobile-2024.firebaseapp.com",
  projectId: "silverback-mobile-2024",
  storageBucket: "silverback-mobile-2024.firebasestorage.app",
  messagingSenderId: "909618343007",
  appId: "1:909618343007:web:49b0682ab04f797a62bfea",
  measurementId: "G-JM3CYFBGHM",
};

const app = initializeApp(firebaseConfig);
if (
  typeof window !== "undefined" &&
  window.firebase &&
  window.firebase.analytics
) {
  getAnalytics(app);
}

export let messaging = {};
if (typeof window !== "undefined" && "serviceWorker" in navigator) {
  messaging = typeof window !== "undefined" ? getMessaging(app) : null;
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const requestForToken = async () => {
  if (
    typeof window !== "undefined" &&
    "Notification" in window &&
    "serviceWorker" in navigator
  ) {
    console.log("Requesting User Permission......");
    // Function to detect Incognito mode
    const isIncognito = await checkIncognitoMode();
    if (isIncognito) {
      console.log("Incognito Mode detected. Notifications may not work.");
      alert(
        "You are in Incognito mode. Push notifications may not be available."
      );
      return; // Return early, as notifications might not work in Incognito mode.
    }

    // Check if permission has already been denied
    if (Notification.permission === "denied") {
      console.log("Notifications have already been denied.");
      return;
    }

    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // Retrieve token from Firebase Cloud Messaging
        const currentToken = await getToken(messaging, {
          usePublicVapidKey: REACT_KEY.REACT_APP_FIREBASE_VAPID_KEY,
        });

        if (currentToken) {
          console.log("Client Token: ", currentToken);
          Cookies.set(process.env.REACT_APP_FCM_TOKEN_KEY, currentToken);
        } else {
          console.log("Failed to generate the registration token.");
        }
      } else {
        console.log("User permission denied.");
      }
    } catch (err) {
      console.log(
        "An error occurred when requesting to receive the token.",
        err
      );
    }
  } else {
    console.log("Browser-specific APIs are not available.");
  }
};

// Helper function to detect Incognito mode
const checkIncognitoMode = () => {
  return new Promise((resolve) => {
    const cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      resolve(true); // Likely in incognito mode
    } else {
      resolve(false); // Normal browsing mode
    }
  });
};
