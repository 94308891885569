/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../services";
import { SILVERBACK, STATUS_CODES } from "../../services/apiConstant";
import { errorToast, successToast } from "components/showToast";

const slice = createSlice({
  name: "settings",
  initialState: {
    message: "",
    siteSettingData: null,
    documentTypes: null,
  },
  reducers: {
    siteSettingSuccess: (state, action) => {
      state.siteSettingData = action.payload;
    },
    documentSuccess: (state, action) => {
      state.documentTypes = action.payload.data;
    },
  },
});

// ** Actions **
const { siteSettingSuccess, documentSuccess } = slice.actions;

export const getSiteSetting = (setIsLoading) => async (dispatch) => {
  if (setIsLoading) {
    setIsLoading(true);
  }
  try {
    const config = {
      headers: {
        accept: "application/json",
        "api-key": process.env.REACT_APP_SITE_SETTING_KEY,
        "secret-key": process.env.REACT_APP_SITE_SETTING_SECURE_KEY,
      },
    };
    await api.get(SILVERBACK.getSiteSetting, config).then((response) => {
      if (response) {
        if (setIsLoading) {
          setIsLoading(false);
        }
        dispatch(siteSettingSuccess(response.data));
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//This function will fetch document types configurations
export const getDocumentDataTypes = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.documentType).then((response) => {
      if (response) {
        dispatch(documentSuccess(response.data));
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// update Site Setting
export const updateSiteSetting =
  (requestParam, setIsLoading, closeDialog) => async (dispatch) => {
    api
      .post(SILVERBACK.updateSiteSetting, requestParam)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          successToast(response?.data?.message);
          closeDialog();
        } else {
          errorToast("Error", response.detail);
        }
        setIsLoading(false);
      })

      .catch(() => {});
  };
export default slice.reducer;
