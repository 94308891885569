import { Suspense, useEffect, useRef, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/scroll-to-top/scrolltotop";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./components/notification";
import secureLocalStorage from "react-secure-storage";
import { STORAGE_KEY } from "utils/appConstant";
import { getSiteSetting } from "store/slice/settings";
import { useDispatch, useSelector } from "react-redux";
import { requestForToken } from "./firebase";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const timeoutRef = useRef(null); // Using ref instead of state for timeout
  const authData = secureLocalStorage.getItem(STORAGE_KEY.USER_AUTH);
  const loginData = authData?.access_token;
  const dispatch = useDispatch();
  const { siteSettingData } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getSiteSetting());
    requestForToken();
  }, []);
  // Function to log out the user
  const logout = () => {
    if (
      loginData &&
      process.env.REACT_APP_MODE != "qa" &&
      process.env.REACT_APP_MODE != "development"
    ) {
      setIsLoggedIn(false);
      secureLocalStorage.clear();
      window.location.href = "/";
    }
  };
  // Reset the inactivity timer whenever there's activity
  const resetTimer = () => {
    // Clear the existing timeout if it exists
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    // Set a new timeout
    timeoutRef.current = setTimeout(
      logout,
      siteSettingData
        ? ((siteSettingData?.DEFAULT_APP_INACTIVE_TIME_SECONDS * 1) / 60) *
            60 *
            1000
        : 5 * 60 * 1000
    );
  };

  // Attach event listeners for user activity
  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll", "click"];
    events.forEach((event) => window.addEventListener(event, resetTimer));
    // Initial inactivity timer
    resetTimer();
    // Cleanup listeners on component unmount
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []); // Empty dependency array ensures this effect only runs once (on mount)

  // If the user is logged out, we don’t need to render the rest of the app
  if (!isLoggedIn && process.env.REACT_APP_MODE != "development") {
    return <div style={{ height: "100vh", background: "black" }}></div>;
  }
  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Suspense fallback={<div>Loading...</div>}>
              <Router />
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
      {/* <Toaster
        theme="colored"
        position="top-left"
        autoClose={5000}
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          success: {
            style: {
              background: 'green',
            },
          },
          error: {
            style: {
              background: 'red',
            },
          },
        }}
      /> */}
      <Notification />
    </>
  );
}

export default App;
